@media print {
    body {
        margin: 0
    }
    .no-printable-block {
        display: none;
    }
}
.MuiCalendarPicker-root:has(.MuiMonthPicker-root) > :first-child {
    display: none;
}